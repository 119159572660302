<template>
  <div class="app-container v">
    <!--工具栏-->
    <div class="head-container">
      <el-input v-model="query.value" clearable placeholder="根据品牌名搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <!-- 新增 -->
      <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <eForm ref="form" :is-add="isAdd" />
    <!--表格渲染-->
    <el-table v-loading="loading" row-key="id" :data="data" size="small" height="200px">
      <el-table-column type="index" fixed />
      <el-table-column label="Logo" fixed width="150px">
        <template slot-scope="scope">
          <img :src="scope.row.logo" height="32px" style="margin: -15px 0;" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="品牌名" :show-overflow-tooltip="true" />
      <el-table-column prop="createAt" label="创建时间" min-width="150" :formatter="r => {return new Date(r.createAt).format('yyyy/MM/dd hh:mm');}" />
      <el-table-column width="100" fixed="right">
        <div class="row-commands" slot-scope="scope" v-if="scope.row.entId === user.entId">
          <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>

<script>
import { del } from "@/api/brand";
import checkPermission from "@/utils/permission";
import { parseTime } from "@/utils/index";
import initData from "@/mixins/initData";
import eForm from "./form";
import { mapGetters } from "vuex";

export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      loading: false,
      query: {
        value: null,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = "api/brand";
      const sort = "createAt";
      this.params = { page: this.page, size: this.size, sort: sort };
      const query = this.query;
      if (query.value) {
        this.params["name"] = query.value;
      }
      return true;
    },
    add() {
      this.isAdd = true;
      const _this = this.$refs.form;
      _this.restForm();
    },
    //修改
    edit(data) {
      this.isAdd = false;
      const _this = this.$refs.form;
      _this.restForm(JSON.parse(JSON.stringify(data)));
    },

    //删除当前行数据功能
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
  },
};
</script>